import React from 'react';
import { graphql, Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Layout from '../components/Layout';
import Callout from '../components/Callout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Quote from '../components/Quote';
import Hero from '../components/Hero';
import Resource from '../components/Resource';
import ToggleClassOnVisible from '../components/ToggleClassOnVisible';
import aboutPageHeroVideo from '../videos/Abbott-Website-loop-home-lead-1400x680.mp4';
import { MEDIA_TYPE } from '../utils/constants';
import { getKeyedResources } from '../utils/resource-helper';

const IndexPage = ({ data, pageContext }) => {
  const { t } = useTranslation('home');
  const path = useTranslation('paths').t;

  const {
    citizenScientist,
    livingBestLife,
    nutrientWise,
    familyActivity,
  } = getKeyedResources(data);

  const fadeInDelays = [0, 300, 600];

  const textOverlay = (
    <div className="home__text_overlay_container">
      <Container fullWidth={true}>
        <div className="home__text_overlay_inner_container">
          <div className="home__text_overlay_section text-bold">
            {t('heroSection1')}
            <span className="color-green">{t('heroSection2')}</span>
          </div>
          <span className="home__text_overlay_title">{t('heroTitle1')} </span>
          <span className="home__text_overlay_title2 text-bold color-yellow">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  return (
    <Layout title="Home" className={pageContext.lang}>
      <Hero
        mediaType={MEDIA_TYPE.MP4_VIDEO}
        mediaSource={aboutPageHeroVideo}
        textOverlay={textOverlay}
        className="mb-0"
      />
      <Section>
        <ToggleClassOnVisible
          delay={fadeInDelays[0]}
          extraClasses="appear-on-visible"
        >
          <Container>
            <Row>
              <Column size={6}>
                <Image
                  filename={t('homeIntroImage')}
                  className="home-about__img"
                />
              </Column>
              <Column size={6}>
                <h2 className="intro__title">{t('homeIntroTitle')}</h2>
                <p dangerouslySetInnerHTML={{ __html: t('homeIntroBody') }} />
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <div className="audience">
                  <Link to={path('/educators/').path}>
                    <Image filename={t('educatorsImage')} />
                    <div className="audience-content">
                      <h2>{t('educatorsTitle')}</h2>
                      <p>{t('educatorsBody')}</p>
                    </div>
                  </Link>
                </div>
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <div className="audience">
                  <Link to={path('/families/').path}>
                    <Image filename={t('familiesImage')} />
                    <div className="audience-content">
                      <h2>{t('familiesTitle')}</h2>
                      <p>{t('familiesBody')}</p>
                    </div>
                  </Link>
                </div>
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <div className="audience">
                  <Link to={path('/employees/').path}>
                    <Image filename={t('employeesImage')} />
                    <div className="audience-content">
                      <h2>{t('employeesTitle')}</h2>
                      <p>{t('employeesBody')}</p>
                    </div>
                  </Link>
                </div>
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <ToggleClassOnVisible
            delay={fadeInDelays[0]}
            extraClasses="appear-on-visible"
          >
            <h2 className="featured-activities__title">
              {t('activitiesHeader')}
            </h2>
            <p className="mb-2">{t('activitiesIntro')}</p>
          </ToggleClassOnVisible>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                {citizenScientist ? (
                  <Resource
                    tophat={citizenScientist.tophat}
                    title={citizenScientist.title}
                    duration={citizenScientist.duration}
                    description={citizenScientist.description}
                    img={citizenScientist.img}
                    actions={citizenScientist.actions}
                  />
                ) : (
                  <Resource
                    tophat={familyActivity.tophat}
                    title={familyActivity.title}
                    duration={familyActivity.duration}
                    description={familyActivity.description}
                    img={familyActivity.img}
                    actions={familyActivity.actions}
                  />
                )}
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                {livingBestLife ? (
                  <Resource
                    tophat={livingBestLife.tophat}
                    title={livingBestLife.title}
                    duration={livingBestLife.duration}
                    description={livingBestLife.description}
                    img={livingBestLife.img}
                    actions={livingBestLife.actions}
                  />
                ) : (
                  <Resource
                    tophat={familyActivity.tophat}
                    title={familyActivity.title}
                    duration={familyActivity.duration}
                    description={familyActivity.description}
                    img={familyActivity.img}
                    actions={familyActivity.actions}
                  />
                )}
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={nutrientWise.tophat}
                  title={nutrientWise.title}
                  duration={nutrientWise.duration}
                  description={nutrientWise.description}
                  img={nutrientWise.img}
                  actions={nutrientWise.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Quote
          img="img-home-melissa-brotz-quote.jpg"
          quote={t('quoteBody')}
          author={t('quoteAuthor')}
          authorDescription={t('quoteAuthorDescription')}
        />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query HomeResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            download
            noIcon
            asLink
            moduleName
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default IndexPage;
